@import '~antd/dist/antd.css';

/***********BSD Colors***********/
/*Text Colors*/
.darkBlueText{color:#0D4D9B;}
.mediumBlueText{color:#3B77BA;}
.tealText{color:#4ECDC4;}
.lightwhiteText{color:#E6F7FF;}
.veryLightOrangeText{color:#FFF7E6;}
.orangeText{color:#FA8C16;}
.lightOrangeText{color:#FFC069;}
.paleOrangeText{color:#FFE7BA;}
.redText{color:#DB1D39;}
.blackText{color:#292F36;}
.whiteText{color: #fff;}
.greyText{color: #666a6e;}
/*Background Colors*/
.darkBlueBG{background-color:#0D4D9B;}
.mediumBlueBG{background-color:#3B77BA;}
.tealBG{background-color:#4ECDC4;}
.lightwhiteBG{background-color:#E6F7FF;}
.veryLightOrangeBG{background-color:#FFF7E6;}
.orangeBG{background-color:#FA8C16;}
.lightOrangeBG{background-color:#FFC069;}
.paleOrangeBG{background-color:#FFE7BA;}
.redBG{background-color:#DB1D39;}   
.blackBG{background-color:#292F36;}
.whiteBG{background-color:#FFF}
.greyBG {background-color: #dfe3e6;}
/***********BSD Fonts***********/

@font-face {
    font-family: BebasNeue;
    src: url("../assets/Fonts/BebasNeue-Regular.ttf");
    font-weight: normal;
}
@font-face {
    font-family: NotoSans;
    src: url("../assets/Fonts/NotoSans-Regular.ttf");
    font-weight: normal;
}
@font-face {
    font-family: NotoSansBold;
    src: url("../assets/Fonts/NotoSans-Bold.ttf");
    font-weight: normal;
}
@font-face {
    font-family: NotoSansBoldItalic;
    src: url("../assets/Fonts/NotoSans-BoldItalic.ttf");
    font-weight: normal;
}
@font-face {
    font-family: NotoSansItalic;
    src: url("../assets/Fonts/NotoSans-Italic.ttf");
    font-weight: normal;
}

.bebas{
    font-family: BebasNeue;
}
.Noto{
    font-family: NotoSans;
}
.NotoB{
    font-family: NotoSansBold;
}
.NotoBI{
    font-family: NotoSansBoldItalic;
}
.NotoI{
    font-family: NotoSansItalic;
}
.logo{
    margin-top: 20px;
}
.logo img {
    vertical-align: text-bottom;
}

.logo span {
    font-family: BebasNeue;
    color: #fff;
    font-size: 2.4em;
    display: inline-block;
}
/********Overwrite antd styles**************/
.ant-layout-footer{
    background-color: white;
}
.ant-layout{
    background-color: white;
}
.ant-dropdown-menu-item-active:hover
{
    background-color:#FA8C16;
    font-weight: 600;
    color:#FFF!important;
}

.ant-dropdown-menu-item-active:hover > i
{
    color:#FFF!important;
}
.ant-menu-dark > .ant-menu-item {
    color: #FA8C16;
}
.ant-menu-vertical.ant-menu-dark > .ant-menu-item, .ant-menu-dark.ant-menu-inline-collapsed > .ant-menu-item {
    padding-bottom: 45px !important;
    padding-top: 10px !important;
}
.ant-menu.ant-menu-dark > .ant-menu-item-selected {
    color: #FFF;
    background-color: #000;
    border-right: 4px solid #FA8C16;
}
.ant-menu.ant-menu-dark.userNav > .ant-menu-item-selected {
    border-right: 4px solid #4ECDC4;
}

.realx > .ant-menu-dark > .ant-menu-item {
    color: #fff;
}
.realx > .ant-menu.ant-menu-dark > .ant-menu-item-selected{
    border-right: 4px solid #4ECDC4;
    color: #4ECDC4;
}
.realx > .userNav > .ant-menu-item {
    color: #fff;
    margin-top: 15px;
}
.realx .sideMenuIcons, .realx .userNav .sideMenuIcons {
    vertical-align: middle;
    color: #fff;
}
.realx > .ant-menu-dark >.ant-menu-item-selected >.anticon {
    color: #4ECDC4;
}

.ant-menu-dark {
    background: #292F36;
}
.userNav > .ant-menu-item {
    color: #4ECDC4;
    margin-top: 15px;
}
.logo_img {
    margin-left: 5px;
    margin-right: 20px;
}
.ant-menu-inline-collapsed {
    width: 35px;
    margin-left: 10px;
}
.logo{
    height: 40px;
    overflow: hidden;
}
.ant-menu-inline-collapsed.ant-menu.ant-menu-dark > .ant-menu-item-selected {
    border-right: 0px;
    background: #292F36;
}
.ant-menu-inline-collapsed.ant-menu.ant-menu-dark.userNav > .ant-menu-item-selected {
    border-right: 0px;
    background: #292F36;
}

.ant-menu-vertical .ant-menu-item, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-right .ant-menu-item, .ant-menu-inline .ant-menu-item{
    padding: 10px !important
}
.ant-layout-sider {
    background: #292F36;
}
.ant-layout-sider-trigger {
    background: #292F36;
}

.sideMenuIcons {
    vertical-align: middle;
    color: #FA8C16;
}

.userNav .sideMenuIcons {
    vertical-align: middle;
    color: #4ECDC4;
}

.ant-layout-footer {
    background-color: #dfe3e6;
}
.rxFooter.ant-layout-footer {
    background-color: #1A1A1A;
}

.ant-upload-picture-card-wrapper::after {
    clear: none;
}
.ant-upload-picture-card-wrapper{
    display:contents!important;
}
.avatar-uploader > .ant-upload {
    width: 128px;
    height: 128px;
  }
.ant-radio-checked .ant-radio-inner{
    border-color: #4ECDC4 !important;
    border-radius: 0 !important;
}
.ant-radio-wrapper{
    color: #0D4D9B;
}
.ant-radio-inner{
    border-color: #4ECDC4 !important;
    border-radius: 0 !important;
    background-color: rgba(255,255,255,0);
}
.ant-radio-inner::after{
    background-color: #4ECDC4 !important;
    border-radius: 0 !important;
    top:2px!important;
    left:2px!important;
    width: 10px;
    height: 10px;
}
.ant-progress-inner {
    border:1px solid #4ECDC4;
    background-color: rgba(255, 255, 255, 0);
}
.ant-btn{
    background-color: #3B77BA;
    color:#DFDFDF;
    border-radius: 2px;
    padding-left: 20px;
    padding-right:20px;
    min-width: 100px;
}
.ant-btn-primary{
    background-color: #0D4D9B;
}
.ant-btn-circle{
    min-width: 32px;
    padding-right: 0;
    padding-left: 0;
    text-align: center;
    border-radius: 50%;
}

.ant-drawer-open{
    margin-left: 55px;
    margin-top: 64px;
}
/**********BSD Styles*************/
.smallText{
    font-size: 0.7em;
}
.progressStatsStyle{
    padding: 0 2%;
    margin: auto;
    border-right: 1px solid #80808033;
}

.hotspotButtonsIcons {
    margin-right: 10px;
    vertical-align: text-top;
}

a-scene {
    height: 600px;
    width: 1200px;
    /* background-color: black; */

}
.container{
    min-height: 360px;
    padding: 24px 24px 0px;
}
.activeButton {
    border: 1px solid #0D4D9B;
    padding: 5px 12px;
    color: #0D4D9B;
    text-align: center;
    background-color: #d5edf5;
}
.progressCard{
    border: 1px solid #ccc;
    padding: 10px;
    background:white;
    margin:10px;
}
.newCourseCard{
    border-radius: 6px;
    background:white;
    margin:10px;
}
.teamMateCard {
    border-radius: 6px;
    background: white;
    margin: 10px;
}
.teamMateCard:hover {
    box-shadow: 0 0 9px rgba(99,99,99,.6)
}

#UserCourseInfo{
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 10px;
}

#ClassCourseInfo {
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    padding: 10px;
    margin: 20px 0 20px 0;
}

.browseCourseCard {
    border: 1px solid #4ECDC4;
    border-radius: 6px;
    color: rgba(0, 0, 0, 0);
    /* margin:1% 0; */
}
div.browseCourseCard:hover{
    border: 1px solid #FFC069;
    border-radius: 6px;
    /* background: rgba(0, 0, 0, 0.8); */
    color:#FA8C16;
}
div.browseCourseCard:hover > a{
    color:#FA8C16;
}
div.browseCourseCard:hover > a:hover{
    color:#FA8C16;
}

.courseProfileImg{
    height: 400px;
    width: 100%;
}

.sideMenuItems {
    font-size: 1.7em;
    padding: 5px;
    margin-bottom: 10px
}

.sideMenuText {
    color: #292F36;
    cursor: pointer
}

.courseName{
    z-index: 1000;
    position: absolute;
    top:0;
    bottom:0;
    right:0;
    left:0;
    opacity: 0;
    background: rgba(0, 0, 0, 0.8);
    display:inline-flex;
    align-content: center;
    text-align: center;
    overflow:hidden;

}
.courseName > p{
    margin:auto;
    color: #fff
}
.courseName:hover
{
    opacity: 1;
    
}
.courseImg {
    max-width: 100%;
    height: auto;
    border-radius: 6px;
}

.browseImg{
   max-width: 320px;
   width: 100%;
   height: 150px
}

.showIcons{
    z-index: 1000;
    position: absolute;
    font-size: 30px;
    top:0;
    bottom:0;
    right:0;
    left:0;
    border-radius: 6px;
    opacity: 0;
    background: rgba(0, 0, 0, 0.8);
    border: 1px solid #FFC069;
    display:flex;
    align-content: center;
    text-align: center;

}
.showIcons:hover
{
    opacity: 1;
    
}

div.timelineCards {
    margin-left: 8px;
    width: 130px;
    flex: 0 0 auto;
}

.timelineImageRow {
    width: 100%;
    height: 90px;
    position: relative;
}

.showImgName{
    z-index: 1000;
    position: absolute;
    font-size: 13px;
    top:0;
    bottom:0;
    right:0;
    left:0;
    border-radius: 6px;
    opacity: 0;
    background: rgba(0, 0, 0, 0.8);
    border: 1px solid #FFC069;
    display:flex;
    align-content: center;
    text-align: center;
    color: #fff;
    padding: 5px;
    overflow: hidden;
}
.showImgName:hover
{
    opacity: 1;
}
.highlightBorder {
    box-shadow: 0 0 11px rgba(99,99,99,.7);
    background-color: #d5edf5;
}
.normalBorder {
    border: 1px solid #ddd;
}
.uploadImageRow {
    width: 100%;
    height: 70px;
    position: relative;
}
.hotspotButtons {
    border: 1px solid #666a6e;
    padding: 5px 12px;
    color: #666a6e;
    text-align: center;
}
.hotspotButtons h3{
    font-size: 16px
}

.hotspotButtons h3{
    margin:0;
    padding:0
}

.hotspotButtons p {
    font-size: 12px
}
.accessPublic {
    background-color: #0080003d;
}
.accessTags
{
    background-color: #40a9ff7a;
}
.accessNone
{
    background-color: rgba(245, 34, 45, 0.2);
}
.clear-none
{
    clear:none;
}
.StrippedButton
{
    font-size: 100%;
    font-family: inherit;
    border:0;
    padding:0; 
    background-color: rgba(0, 0, 0, 0);
    pointer-events: auto;
    cursor: pointer;
}
.carouselButton
{
    position: absolute;
    top:36%;
    z-index: 900;
    color:rgba(255, 255, 255, 0.8);
    font-size: 60px;
}

.carouselButtonDark {
    position: absolute;
    top: 36%;
    z-index: 900;
    color: rgba(200, 200, 200, 0.8);
    font-size: 60px;
}

.greenBorder 
{
    border: #1ac998 1px solid;
    padding: 20px;
}

.backGroundLoader
{
    z-index:999;
    position:absolute;
    width:100%;
    height:100%;
}
.centeredStartButton
{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
}
.hidden
{
    display: none;
}
.settingsButton
{
    z-index:999;
    position:absolute;
    left:0;
    bottom:0;
    padding-left: 0;
    padding-right: 0;
    min-width: 50px;
}
.teamCardButtons{
    font-size: 1.1em;
}



.teamCardButtons:hover {
    color: #FA8C16;
    cursor: pointer;
}

.imgGrid {
    text-align: center;
    border: 2px solid #0D4D9B;
    width: 200px;
    padding: 5px;
    color: #0D4D9B;
}

.ant-popover{
    width: 250px;
}

.managerPopOverButton{

    border: 1px solid #ccc;
    color: #666
}

.managerPopOverButton :hover{
    border: 1px solid #0D4D9B;
    color: #0D4D9B;
}

.apexcharts-menu-icon{
    margin-right: 60px;
    /*margin-top: -40px;*/
}

.chartBox{
    border: 1px solid #ccc;
    margin-top: 20px;   
}

.browseCourses {
    width: 310px;
    border:1px solid #ccc
}

.browseCourseBorder {
    border: 1px solid #ccc
}

.loginCard {
    background: #292f36;
    padding: 90px 65px 30px 65px;
    max-height: 600px;
    max-width: 900px;
    margin: 0 auto;
    border-radius: 3px;
    box-shadow: 0 10px 40px #000;
}

.bsd-btn-teal {
    font-family: NotoSans, 'Roboto', 'Ubuntu', 'Helvetica Neue', sans-serif;
    font-weight: 100;
    font-size: small;
    padding: 0px 25px;
    color: #fff;
    background-color: #4ecdc4;
    border-color: #4ecdc4;
    border-radius: 2px;
    line-height: normal;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.bsd-btn-orange {
    font-family: NotoSans, 'Roboto', 'Ubuntu', 'Helvetica Neue', sans-serif;
    font-weight: 100;
    font-size: small;
    padding: 0px 25px;
    color: #fff;
    background-color: #fa8c16;
    border-color: #fa8c16;
    border-radius: 2px;
    line-height: normal;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-result-title{
    color: #fff;
}

.sharedLib{
    z-index: 2000;
}
.hidePanosList{
    z-index: 1000;
    position: absolute;
    font-size: 13px;
    top:0;
    bottom:0;
    right:0;
    left:0;
    opacity: 0.7;
    background: rgba(0, 0, 0, 0.8);
    display:flex;
    align-content: center;
    text-align: center;
    color: #fff;
    padding: 5px;
    overflow: hidden;
}

.fixedButtonDiv {
    position: fixed;
    bottom: -4px;
    right: 50px;
}

.fixedValidateButtonDiv {
    position: fixed;
    bottom: -4px;
    right: 200px;
}

.fixedButton {
    background-color: #f78502;
    color: #fff;
    border-radius: 4px;
    border-color: #eb9028;
    padding: 0px 30px;
    box-shadow: 0 0 6px rgba(150,150,150,.6);
    font-family: NotoSansBold;
}

.fixedButton:focus {
    background-color: #f78502;
    color: #fff;
    border-color: #eb9028;
}
.fixedButton:hover {
    background-color: #ffa126;
    color: #fff;
    border-color: #ffa126;
}

::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.swap-upload .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    max-width: 200px;
    height: 100px;
}
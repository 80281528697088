@import '~antd/dist/antd.css';

/***********Realx Colors***********/
/*Text Colors*/
.rxdarkBlueText {
    color: #0D2340;
}

.rxmediumBlueText {
    color: #165873;
}

.rxlightBlueText {
    color: #88F2F2;
}

.rxdarkTealText {
    color: #0AA696;
}

.rxtealText {
    color: #1BF2CB;
}

.rxdarkPinkText {
    color: #40022A;
}

.rxmediumPinkText {
    color: #6D1D58;
}

.rxbrightPinkText {
    color: #AB2982;
}

.rxlightPinkText {
    color: #F283CF;
}
/*Background Colors*/
.rxblackBG {
    background-color: #1A1A1A;
}
.rxdarkBlueBG {
    background-color: #0D2340;
}

.rxmediumBlueBG {
    background-color: #165873;
}

.rxlightBlueBG {
    background-color: #88F2F2;
}

.rxdarkTealBG {
    background-color: #0AA696;
}

.rxtealBG {
    background-color: #1BF2CB;
}

.rxdarkPinkBG {
    background-color: #40022A;
}

.rxmediumPinkBG {
    background-color: #6D1D58;
}

.rxbrightPinkBG {
    background-color: #AB2982;
}

.rxlightPinkBG {
    background-color: #F283CF;
}
/***Borders***/
.tealBorder {
    border: 1px solid #1BF2CB;
}
.lightBlueBorder {
    border: 1px solid #88F2F2;
}
/*********Buttons***********/
.realx-btn-blue {
    font-family: NotoSans, 'Roboto', 'Ubuntu', 'Helvetica Neue', sans-serif;
    font-weight: 100;
    font-size: small;
    padding: 0px 25px;
    color: #fff;
    background-color: #165873;
    border-color: #165873;
    border-radius: 2px;
    line-height: normal;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.realx-btn-blue:hover,
.realx-btn-blue:focus {
    color: #fff;
    background-color: #88F2F2;
    border-color: #88F2F2;
}

.realx-btn-pink {
    font-family: NotoSans, 'Roboto', 'Ubuntu', 'Helvetica Neue', sans-serif;
    font-weight: 100;
    font-size: small;
    padding: 0px 25px;
    color: #fff;
    background-color: #6D1D58;
    border-color: #6D1D58;
    border-radius: 2px;
    line-height: normal;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.realx-btn-pink:hover,
.realx-btn-pink:focus {
    color: #FFFFFF;
    background-color: #AB2982;
    border-color: #AB2982;
}


.realx-btn-teal {
    font-family: NotoSans, 'Roboto', 'Ubuntu', 'Helvetica Neue', sans-serif;
    font-weight: 100;
    font-size: small;
    padding: 0px 25px;
    color: #fff;
    background-color: #0AA696;
    border-color: #0AA696;
    border-radius: 2px;
    line-height: normal;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.realx-btn-teal:hover,
.realx-btn-blue:focus {
    color: #fff;
    background-color: #1BF2CB;
    border-color: #1BF2CB;
}
.realx-iconbtn
{
    padding-left:10%;
    padding-right:10%;
}
/****** Classes *******/

.headerImage
{
    margin-right: 15px; 
    font-size: 20px;
    height: 40px;
    margin: 12px; 
}
.logoName
{
    margin: 0 15px;
    font-weight:700;
}
.rxprofileCard {
    border: 1px solid #1BF2CB;
    border-radius: 6px;
    padding: 10px;
    background: #1A1A1A;
}

.ant-radio-wrapper {
    color: #0AA696;
}

.realx > .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: #F283CF;
}
.realx > .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon {
    color: #F283CF;
}

.realx > .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: #999;
}

.realx > .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon {
    color: #999;
}

.realx > .ant-steps-navigation > .ant-steps-item::after {
    color: #999;
}

.realx > .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: #999;
}


.rxactiveButton {
    border: 3px solid #0D4D9B;
    padding: 10px 30px 8px 30px;
    color: #F283CF;
    text-align: center;
}

.rxhotspotButtons {
    border: 2px solid #666a6e;
    padding: 10px 30px 7px 30px;
    color: #fff;
    text-align: center;
}

.blackBG > .ant-card-actions {
    background: none;
    border-top-color: #666;
}

.blackBG.ant-card-bordered{
    border-color: #666;
}

.blackBG > .ant-card-actions > li:not(:last-child) {
    border-right-color: #666;
}

.managerPopOverButtonRealX.ant-btn:hover {
    border: 1px solid #4ECDC4;
    color: #4ECDC4
}

.managerPopOverButtonRealXPink.ant-btn:hover {
    border: 1px solid #AB2982;
    color: #AB2982;
}

.teamCardButtonsRealX, .teamCardButtonsRealXPink {
    font-size: 1.1em;
    color: #fff;
}

.teamCardButtonsRealX:hover {
    color: #4ECDC4;
    cursor: pointer;
}

.teamCardButtonsRealXPink:hover {
    color: #AB2982;
    cursor: pointer;
}

.browseCoursesRealX {
    width: 310px;
    border: 1px solid #666
}

.browseCourseBorderRealX {
    border: 1px solid #666
}

.pinkHover:hover {
    color: #AB2982;
}

.tealHover:hover {
    color: #4ECDC4;
}

.realx .ant-drawer-header {
    background-color: #292F36;
    color: #fff;
    border-bottom-color: #000;
}
.realx .ant-drawer-title {
    color: #4ECDC4;
}

.realx .ant-drawer-content {
    background-color: #292F36;
}

.rxblackBG > .ant-popover-inner {
    background-color: #000;
}

.realx > .ant-cascader-picker{
    color: #999;
    background-color: #292F36;
}

.realx > .ant-cascader-picker > .ant-input{
    border-color:#999;
}

.realx > .ant-input-affix-wrapper > .ant-input{
    border-color:#999;
    color: #999;
    background-color: #292F36;
}

.realx .ant-input-search-icon{
    color: #999;
}

.realx .ant-tabs{
    color: #fff;
}
/* .container
{
    padding: 0px;
} */
/* #footer
{
    display:none
} */
.noPadding
{
    padding:0px!important;
}
/* .ant-carousel .slick-slide {
    text-align: center;
    height: 160px;
    line-height: 160px;
    background: #364d79;
    overflow: hidden;
} */
.ant-carousel .slick-dots li button{
    background:black;
}
.ant-carousel .slick-dots li.slick-active button {
    background:blue;
}
.clearfix{
    float:left
}
.ant-upload-picture-card-wrapper{
    display:inline-flex;
   /* flex-direction:column-reverse; */
}
.ant-upload-list{
    order:2;
}
.ant-upload-select{
    order:1;
}
.notAllowed { 
    cursor: not-allowed;
    /* pointer-events: none;*/
} 
 
.App {
    font-family: sans-serif;
    font-size: 1.5rem;
    text-align: center;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.App ul {
    margin: 0;
    padding: 0;
    /* list-style: none; */
}

.App ul li {
    margin:0 8px 8px 0;
    /* padding: 10px 20px; */
    position: relative;
    display: flex;
    align-items: flex-start;
    line-height: 1;
}
.App ul li img{
    border: 1px dashed #d9d9d9;
    width: 102px;
    height: 102px;
}

/* .App li .drag {*/
    /* margin-right: 15px; */
    /* cursor: move; */
/* }  */

.number{
    display: block;
    height: 30px;
    width: 30px;
    line-height: 30px;
    text-align: center;
    z-index: 100;
    position: absolute;
    color:blue;
    background-color: #fff;
    border-radius: 50%;
    border:1px solid blue;
}
.delete{
    display: block;
    height: 30px;
    width: 30px;
    right:0;
    line-height: 30px;
    text-align: center;
    z-index: 100;
    position: absolute;
    color:red;
    /* background-color: #fff;
    border-radius: 50%;
    border:1px solid blue; */
}